import { useMemo } from 'react';
import {
    hasLowerCaseCharValidator,
    hasNumberValidator,
    hasUpperCaseCharValidator,
    lengthValidator,
} from 'utils/validation-rules';

import { MessageKey } from '../kc-app/kc-messages-extension';

export const usePasswordStrengthCriterias = (msgStr: (key: MessageKey, ...args: (string | undefined)[]) => string) => {
    return useMemo(
        () => [
            {
                validators: [lengthValidator],
                message: msgStr('passwordStrengthLevel1'),
            },
            {
                validators: [hasLowerCaseCharValidator],
                message: msgStr('passwordStrengthLevel2'),
            },
            {
                validators: [hasUpperCaseCharValidator, hasNumberValidator],
                message: msgStr('passwordStrengthLevel3'),
            },
            {
                validators: [],
                message: msgStr('passwordIsStrong'),
            },
        ],
        [msgStr]
    );
};
