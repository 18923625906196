import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconApple24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon viewBox="0 0 24 24" fill="none" name="apple-24" {...props}>
        <path 
            d="M12.2227 4.88461C13.0547 4.88461 14.0977 4.30483 14.7188 3.53179C15.2812 2.83121 15.6914 1.85283 15.6914 0.874443C15.6914 0.741576 15.6797 0.608709 15.6562 0.5C14.7305 0.536236 13.6172 1.14018 12.9492 1.94946C12.4219 2.56548 11.9414 3.53179 11.9414 4.52225C11.9414 4.6672 11.9648 4.81214 11.9766 4.86046C12.0352 4.87254 12.1289 4.88461 12.2227 4.88461ZM9.29297 19.5C10.4297 19.5 10.9336 18.7149 12.3516 18.7149C13.793 18.7149 14.1094 19.4758 15.375 19.4758C16.6172 19.4758 17.4492 18.2921 18.2344 17.1325C19.1133 15.8039 19.4766 14.4994 19.5 14.439C19.418 14.4148 17.0391 13.4123 17.0391 10.5979C17.0391 8.15798 18.9141 7.0588 19.0195 6.97425C17.7773 5.13827 15.8906 5.08996 15.375 5.08996C13.9805 5.08996 12.8438 5.95963 12.1289 5.95963C11.3555 5.95963 10.3359 5.13827 9.12891 5.13827C6.83203 5.13827 4.5 7.09504 4.5 10.7912C4.5 13.0861 5.36719 15.514 6.43359 17.0842C7.34766 18.4129 8.14453 19.5 9.29297 19.5Z"
            fill="#000000"
        />
    </Icon>
);
