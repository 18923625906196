import { getKcContext, KcContextBase } from 'keycloakify';

// This one is stolen from keycloakify
type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

type PageId =
    | 'login.ftl'
    | 'register.ftl'
    | 'login-verify-email.ftl'
    | 'login-page-expired.ftl'
    | 'login-reset-password.ftl'
    | 'login-update-password.ftl'
    | 'login-otp.ftl'
    | 'login-config-totp.ftl'
    | 'error.ftl'
    | 'info.ftl';

export const pagesMapping: Record<string, PageId> = {
    login: 'login.ftl',
    loginWithHiddenUserName: 'login.ftl',
    loginWithWrongPassword: 'login.ftl',
    register: 'register.ftl',
    loginVerifyEmail: 'login-verify-email.ftl',
    loginPageExpired: 'login-page-expired.ftl',
    loginResetPassword: 'login-reset-password.ftl',
    loginUpdatePassword: 'login-update-password.ftl',
    loginUpdatePasswordForMigration: 'login-update-password.ftl',
    loginOtp: 'login-otp.ftl',
    loginConfigTotp: 'login-config-totp.ftl',
    error: 'error.ftl',
    info: 'info.ftl',

    loginUsername: 'login.ftl',
    magicLinkForm: 'login.ftl',
    magicLinkConfirm: 'login.ftl',
};

type CustomPageId = 'login-username.ftl' | 'magic-link-form.ftl' | 'magic-link-confirm.ftl';

export const customPagesMapping: Record<string, CustomPageId> = {
    loginUsername: 'login-username.ftl',
    magicLinkForm: 'magic-link-form.ftl',
    magicLinkConfirm: 'magic-link-confirm.ftl',
};

const realm = {
    registrationEmailAsUsername: true,
};

const social = {
    displayInfo: true,
    providers: [
        {
            alias: 'facebook',
            displayName: 'Facebook',
            // iconClasses: "fa fa-facebook",
            loginUrl: '/realms/...',
            providerId: 'facebook',
        },
        {
            alias: 'google',
            displayName: 'Google',
            // iconClasses: "fa fa-google",
            loginUrl: '/realms/...',
            providerId: 'google',
        },
        {
            alias: 'apple',
            displayName: 'Apple',
            // iconClasses: "fa fa-apple",
            loginUrl: '/realms/...',
            providerId: 'apple',
        },
    ],
};

const url = {
    loginAction: '/login',
    loginRestartFlowUrl: '/login',
    loginUrl: '/login',
    registrationUrl: '/register',
    resourcesCommonPath: '/keycloak_static/resources/resources_common',
    resourcesPath: '/keycloak_static/resources',
};

// We need in to define type of mockData
const { kcContext: defaultKcContext } = getKcContext();
type KeycloakMockData = DeepPartial<NonNullable<typeof defaultKcContext>>;

export type KcContext = NonNullable<typeof defaultKcContext>;

export type KcContext_LoginUsername = Omit<KcContextBase.Login, 'pageId'> & { pageId: 'login-username.ftl' };
export type KcContext_MagicLinkForm = Omit<KcContextBase.Login, 'pageId'> & { pageId: 'magic-link-form.ftl' };
export type KcContext_MagicLinkConfirm = Omit<KcContextBase.Login, 'pageId'> & {
    pageId: 'magic-link-confirm.ftl';
    email?: string;
};
export type KcContextWithCustomPages =
    | KcContextBase
    | KcContext_LoginUsername
    | KcContext_MagicLinkForm
    | KcContext_MagicLinkConfirm;

export const getMockedKcContext = (mockPageName: string) => {
    const mockPageId = pagesMapping[mockPageName];

    // It is enough to generate mock for the current page only
    const mockData: KeycloakMockData[] = [mockPageId].map(pageId => ({
        pageId,
        message: undefined,
        realm,
        social,
        usernameHidden: mockPageName === 'loginWithHiddenUserName',
        url,
    }));

    const errorFtl = mockData.find(d => d.pageId === 'error.ftl');
    if (errorFtl) {
        errorFtl.message = {
            // Next fields are from real context, but they are nit defined in keyckoakify types
            // error: true,
            // success: false,
            // warning: false,
            summary: 'Cookie not found. Please make sure cookies are enabled in your browser.',
            type: 'error',
        };
    }

    const infoFtl = mockData.find(d => d.pageId === 'info.ftl');
    if (infoFtl) {
        infoFtl.message = {
            summary: 'Success! You may now return to original tab',
            type: 'info',
        };
    }

    if (mockPageName === 'loginWithWrongPassword'|| mockPageName === 'loginUsername') {
        const loginFtl = mockData.find(d => d.pageId === 'login.ftl');
        if (loginFtl) {
            loginFtl.message = {
                summary: 'Invalid password.',
                type: 'error',
            };
        }
    }

    const { kcContext } = getKcContext({
        mockPageId,
        mockData,
    });

    // if (kcContext && mockPageName !== 'loginWithWrongPassword' && mockPageName !== 'loginUsername') {
    if (kcContext && mockPageName !== 'loginWithWrongPassword') {
        // We need fields errors for specific errors only
        kcContext.messagesPerField.exists = () => false;
    }

    if (kcContext && mockPageName === 'loginUpdatePasswordForMigration') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Just believe me. It is custom field
        kcContext.is_migration = true;
    }

    const kcContextWithCustomPages = kcContext as KcContextWithCustomPages;

    const customPageId: CustomPageId = customPagesMapping[mockPageName];
    kcContextWithCustomPages.pageId = customPageId || mockPageId;

    if (kcContextWithCustomPages?.pageId === 'magic-link-confirm.ftl') {
        kcContextWithCustomPages.email = 'vladryba@gmail.com';
    }

    if (kcContextWithCustomPages?.pageId === 'magic-link-form.ftl') {
        kcContextWithCustomPages.auth.attemptedUsername = 'vladryba@gmail.com';
    }

    return kcContextWithCustomPages;
};
