import { useEffect } from 'react';
import { useSearchParam } from 'react-use';

const setCookie = (name: string, value: string) => {
    const now = new Date();
    const expires = now.setFullYear(now.getFullYear() + 1).toString();

    const domain = location.host.split('.').slice(-2).join('.').split(':')[0];

    document.cookie = `${name}=${value}; expires=${expires}; path=/; domain=.${domain}`;
};

export function useB2bCookie() {
    const from = useSearchParam('from');
    const country = useSearchParam('country');

    useEffect(() => {
        if (from === 'b2b') {
            const countryStr = country ? '&country=' + country : '';
            const value = `/sso/auth/?retpath=${encodeURIComponent('/home/?from=b2b' + countryStr)}`;

            setCookie('auto_login_return_uri', value);
        }
    }, [from, country]);
}
