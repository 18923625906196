import { ChangeEventHandler, useCallback } from 'react';

export const useSavedFieldLs = (): [
    onChangeSavedField: ChangeEventHandler<HTMLInputElement>,
    getSavedField: (name: string) => string | number | readonly string[]
] => {
    const onChangeSavedField = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
        const input = e.target;

        if (input.value && input.value.length) {
            sessionStorage.setItem(input.name, input.value);
            if(input.name === 'email') {
                sessionStorage.setItem('username', input.value);
            }
            if(input.name === 'username') {
                sessionStorage.setItem('email', input.value);
            }
        } else {
            sessionStorage.removeItem(input.name);
            if(input.name === 'username') {
                sessionStorage.removeItem('email');
            }
            if(input.name === 'email') {
                sessionStorage.removeItem('username');
            }
        }
    }, []);

    const getSavedField = useCallback((name: string) => sessionStorage.getItem(name) || '', []);

    return [onChangeSavedField, getSavedField];
};
