export interface LogoProps {
    className?: string;
}

export const Logo = ({ className }: LogoProps) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="108"
            height="24"
            viewBox="0 0 108 24"
            fill="none"
        >
            <g clipPath="url(#clip0_704_2904)">
                <path
                    d="M18.9479 9.11884C16.8413 9.11884 15.7213 9.89171 15.7213 12.1317V19.3303H11.3217V4.98604H15.5606V7.70647C16.2274 6.02736 17.4278 4.96039 19.4796 4.88002C19.6934 4.88002 19.9328 4.88002 20.1465 4.90567V9.1992C19.666 9.1462 19.3463 9.11884 18.9461 9.11884"
                    fill="currentColor"
                />
                <path
                    d="M21.8803 19.332H26.3072V4.98604H21.8803V19.332ZM21.8803 3.52066H26.2799V0H21.8803V3.52066Z"
                    fill="currentColor"
                />
                <path
                    d="M35.7254 16.2645C37.4848 16.2645 38.9246 14.8247 38.9246 12.159C38.9246 9.4933 37.4848 8.07922 35.7254 8.07922C33.9659 8.07922 32.5518 9.1992 32.5518 12.159C32.5518 15.1188 33.9915 16.2645 35.7254 16.2645ZM37.1651 4.66629C40.8448 4.66629 43.4849 7.46537 43.4849 12.159C43.4849 16.8527 40.8448 19.6791 37.1651 19.6791C35.592 19.6791 33.6991 18.9849 32.7655 17.4135V24H28.366V4.98604H32.7655V6.93189C33.6991 5.35879 35.592 4.66629 37.1651 4.66629Z"
                    fill="currentColor"
                />
                <path d="M49.2438 0H44.8442V19.332H49.2438V0Z" fill="currentColor" />
                <path
                    d="M61.1669 10.6116C61.1139 8.82474 59.8075 7.9185 58.1541 7.9185C56.6083 7.9185 55.1942 8.71872 54.9549 10.6116H61.1669ZM58.3405 19.6774C53.6741 19.6774 50.4749 16.8236 50.4749 12.0787C50.4749 7.33371 53.9682 4.56027 58.1541 4.56027C62.9007 4.56027 65.8332 8.18695 65.5408 13.279H54.9019C55.0352 15.3052 56.6083 16.2662 58.2088 16.2662C59.8093 16.2662 60.9275 15.6797 61.4354 14.8264H65.4878C64.2088 18.212 61.2216 19.6791 58.3422 19.6791"
                    fill="currentColor"
                />
                <path
                    d="M87.6515 10.6116C87.5985 8.82474 86.2921 7.9185 84.6387 7.9185C83.0929 7.9185 81.6788 8.71872 81.4394 10.6116H87.6515ZM84.825 19.6774C80.1587 19.6774 76.9595 16.8236 76.9595 12.0787C76.9595 7.33371 80.4528 4.56027 84.6387 4.56027C89.3853 4.56027 92.3178 8.18695 92.0237 13.279H81.3847C81.5181 15.3052 83.0912 16.2662 84.6917 16.2662C86.2921 16.2662 87.4104 15.6797 87.9182 14.8264H91.9707C90.69 18.212 87.7045 19.6791 84.825 19.6791"
                    fill="currentColor"
                />
                <path
                    d="M102.451 4.66629C106.13 4.66629 107.276 6.82588 107.276 10.7193V19.332H102.876V11.8136C102.876 9.76004 102.796 8.08093 100.664 8.08093C98.7983 8.08093 97.9981 9.33428 97.9981 11.9744V19.3337H93.5985V4.98604H97.9981V6.87888C98.6376 5.81191 100.211 4.66629 102.451 4.66629Z"
                    fill="currentColor"
                />
                <path
                    d="M71.5186 15.6421L71.5135 8.10487H75.8942V4.98604H71.5135V1.06697H67.1139V11.4409C67.1139 13.2363 67.2405 15.6421 70.6842 15.6421C70.6842 16.2662 71.3853 19.6158 76.3918 19.3115V15.6421H71.5186Z"
                    fill="currentColor"
                />
                <path
                    d="M4.85489 15.6421L4.84976 8.10487H9.23051V4.98604H4.84976V1.06697H0.450195V11.4409C0.450195 13.2363 0.576728 15.6421 4.02046 15.6421C4.02046 16.2662 4.72152 19.6158 9.72809 19.3115V15.6421H4.85489Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_704_2904">
                    <rect width="107.1" height="24" fill="white" transform="translate(0.450195)" />
                </clipPath>
            </defs>
        </svg>
    );
};
