import { useEffect, useState } from 'react';

export const useChatReady = () => {
    const [chatReady, setChatReady] = useState(false);

    useEffect(() => {
        void window.__hsConversationsOnReadyPromise?.then(() => {
            setChatReady(true);
        });
    }, [setChatReady]);

    return chatReady;
};
