import { MessageKey } from 'kc-app/kc-messages-extension';
import { getMsg as kcGetMsg, KcLanguageTag } from 'keycloakify';

declare type KcContextLike = {
    locale?: {
        currentLanguageTag: KcLanguageTag;
    };
};

type GetMsg = (kcContext: KcContextLike) => {
    msgStr: (key: MessageKey, ...args: (string | undefined)[]) => string;
    msg: (key: MessageKey, ...args: (string | undefined)[]) => JSX.Element;
    advancedMsg: <Key extends string>(key?: Key, ...args: (string | undefined)[]) => JSX.Element;
    advancedMsgStr: <Key_1 extends string>(key?: Key_1, ...args: (string | undefined)[]) => string;
};

export const getMsg = (kcContext: KcContextLike) => {
    const { msg, msgStr, advancedMsg, advancedMsgStr } = (kcGetMsg as GetMsg)(kcContext);

    return {
        msg,
        msgStr,
        advancedMsg: (key: string | undefined, ...args: (string | undefined)[]) => advancedMsg(key || '', ...args),
        advancedMsgStr: (key: string | undefined, ...args: (string | undefined)[]) =>
            advancedMsgStr(key || '', ...args),
    };
};
