import { useMemo } from 'react';

import { sendGtmEvent } from './send-analytics-event';

export const useAnalytics = () => {
    return useMemo(
        () => ({
            sendGtmEvent: sendGtmEvent,
        }),
        []
    );
};
