import b from 'b_';
import { IconSymbolsCross24 } from 'icons/symbols/cross/icon-symbols-cross-24';

import './message-toast.css';

interface MessageToastProp {
    message: string;
    onClose: () => void;
}

export const MessageToast = ({ message, onClose }: MessageToastProp) => {
    const bl = b.lock('message-toast');

    return (
        <div className={bl()}>
            <div className={bl('content')}>
                <button onClick={onClose} className={bl('close-button')}>
                    <IconSymbolsCross24 />
                </button>
                {message
                    .replaceAll('<br>', '\n')
                    .split('\n')
                    .map((m, idx) => (
                        <div key={idx}>{m}</div>
                    ))}
            </div>
        </div>
    );
};
