import { getKcContext } from 'keycloakify';
import ReactDOM from 'react-dom';

import { KcApp } from './kc-app/kc-app';
import { getMockedKcContext } from './kc-app/kc-context';
import { setupSentry } from './sentry';
import { PagesList } from './ui-components/pages-list/pages-list';

const isProduction = process.env.NODE_ENV === 'production';
const getMockPageName = () => window.location.pathname.split('/').pop() || 'login';

const kcContext = isProduction ? getKcContext().kcContext : getMockedKcContext(getMockPageName());

setupSentry(process.env.NODE_ENV);

ReactDOM.render(
    <>
        {!isProduction && <PagesList />}
        {kcContext ? <KcApp kcContext={kcContext} /> : <div />}
    </>,
    document.getElementById('root')
);
