import type { KcLanguageTag } from 'keycloakify';
import { useEffect, useState } from 'react';

export type ShouldShowSupportChatParams = {
    locale: KcLanguageTag;
    allowedLocales: string[];
    allowedCountryCodes: string[];
};

export type CountryByIpResponse = {
    country_code: string;
};

export const useShouldShowSupportChat = (params: ShouldShowSupportChatParams) => {
    const { locale, allowedLocales, allowedCountryCodes } = params;

    const [countryCode, setCountryCode] = useState<string>('');

    useEffect(() => {
        void fetch('https://tripleten.com/country-by-ip')
            .then(response => response.json())
            .then((data: CountryByIpResponse) => {
                setCountryCode(data.country_code);
            });
    }, []);

    return allowedLocales.includes(locale) && allowedCountryCodes.includes(countryCode);
};
