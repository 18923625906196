import b from 'b_';
import { KcProps } from 'keycloakify';
import { memo } from 'react';
import { useCssAndCx } from 'tss-react';
import { getMsg } from 'utils/kc-messages';

import { Form } from '../../form/form';
import { KcContext_MagicLinkConfirm } from '../../kc-context';

import './magic-link-confirm.css';

export const MagicLinkConfirm = memo(({ kcContext }: { kcContext: KcContext_MagicLinkConfirm } & KcProps) => {
    const { url, email } = kcContext;

    const { msgStr, advancedMsgStr } = getMsg(kcContext);
    const bl = b.lock('magic-link-confirm');
    const { cx } = useCssAndCx();

    return (
        <Form action={url.loginAction} kcContext={kcContext} className={bl('content')}>
            <div className={bl('text-block')}>
                <span className={bl('title')}>{msgStr('magicLinkConfirm')} </span>
                <span className={cx(bl('title', { email: true }))}>
                    {email || msgStr('yourEmail')}
                </span>
            </div>
            <div className={bl('button-container')}>
                <a className={bl('button')} href={url.loginAction}>
                    {msgStr('magicLinkConfirmButton')}
                </a>
            </div>
        </Form>
    );
});
