import b from 'b_';
import { useEffect, useMemo, useState } from 'react';
import { useCssAndCx } from 'tss-react';
import { Validator } from 'utils/validation-rules';

import './password-strength-meter.css';

export interface PasswordStrengthMeterProps {
    password: string;
    className?: string;
    hintText: string;
    strengthLevels: {
        validators: Validator[];
        message: string;
    }[];
}

export const PasswordStrengthMeter = (props: PasswordStrengthMeterProps) => {
    const { password, className, hintText, strengthLevels } = props;

    const [strengthLevel, setStrengthLevel] = useState(0);
    const [meterTitle, setMeterTitle] = useState(strengthLevels[0].message);

    const bl = b.lock('password-strength');
    const { cx } = useCssAndCx();

    useEffect(() => {
        for (let level = 0; level < strengthLevels.length; level++) {
            const validators = strengthLevels[level].validators;

            // validators.length === 0 means that it's the last level — "Password is strong enough"
            const shouldSkipLevel = validators.length !== 0 && validators.every(validator => validator(password));

            if (!shouldSkipLevel) {
                setStrengthLevel(level + 1);
                setMeterTitle(strengthLevels[level].message);

                return;
            }
        }
    }, [password, strengthLevels]);

    const barLevelStyle = useMemo(
        () => ({
            width: `${(100 / strengthLevels.length) * strengthLevel}%`,
        }),
        [strengthLevels, strengthLevel]
    );

    const isSuccessState = strengthLevel === strengthLevels.length;

    return (
        <div className={cx(bl(), className)}>
            <div className={bl('meter', { success: isSuccessState })}>
                <div className={bl('meter-title')}>{meterTitle}</div>
                <div className={bl('meter-bar')}>
                    <div className={bl('meter-bar-level')} style={barLevelStyle}></div>
                </div>
            </div>

            <div className={bl('hint')}>{hintText}</div>
        </div>
    );
};
