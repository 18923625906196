declare global {
    interface Window {
        gtm?: (options: Record<string, unknown>) => void;
    }
}

export const sendGtmEvent = (name: string, params?: Record<string, unknown>) => {
    if (window && window.gtm) {
        // Подключение gtm происходит в компоненте GoogleTagManager
        window.gtm({
            ...params,
            event: 'custom_events',
            eventCategory: name,
        });
    }
};
