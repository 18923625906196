import b from 'b_';
import { KcContextBase, KcProps } from 'keycloakify';
import { memo, useCallback, useState } from 'react';
import { getMsg } from 'utils/kc-messages';

import { ReactComponent as ErrorSvg } from './error.svg';
import { ReactComponent as InfoSvg } from './info.svg';

import './error.css';

type KcActionContext = {
    actionUri?: string;
    pageRedirectUri?: string;
    skipLink?: boolean;
};

export const Error = memo(
    ({
        kcContext,
    }: {
        kcContext: (KcContextBase.Error | KcContextBase.Info | KcContextBase.LoginPageExpired) &
            KcActionContext & { requiredActions?: string[] };
    } & KcProps) => {
        const bl = b.lock('error');

        const { pageId, actionUri = '', requiredActions } = kcContext;
        const [disabledButton, setDisabledButton] = useState(false);

        const kcMessage = kcContext.message?.summary;

        const { msgStr } = getMsg(kcContext);

        const isInfoPage = pageId === 'info.ftl';
        const isLoginPageExpiredPage = pageId === 'login-page-expired.ftl';

        const SvgImage = isInfoPage || isLoginPageExpiredPage ? InfoSvg : ErrorSvg;
        const title = isInfoPage
            ? kcMessage
            : isLoginPageExpiredPage ? msgStr('expiredPageTitle') : msgStr('errorPageTitle');
        const description = isInfoPage
            ? undefined
            : isLoginPageExpiredPage ? msgStr('expiredPageDescription') : kcMessage;

        const hasAction = Boolean(kcContext?.actionUri);
        const url = hasAction ? kcContext?.actionUri : msgStr('homePage');

        const redirectUrl = kcContext?.pageRedirectUri;

        const skipBackToMainButton = kcContext?.skipLink;

        const onClickButton = useCallback(() => {
            setDisabledButton(true);
        }, []);

        if (redirectUrl) {
            window.location.href = redirectUrl;
            return null;
        }

        if (actionUri && requiredActions && requiredActions.length) {
            if (requiredActions[0] === 'FAST_REGISTRATION_PASSWORD') {
                window.location.href = actionUri;

                return null;
            }
        }

        return (
            <div className={bl('content')}>
                <SvgImage className={bl('image')} />
                <div className={bl('title')}>{title}</div>
                {description && <div className={bl('message')}>{description}</div>}
                {!skipBackToMainButton && (
                    <div className={bl('button-container')} onClick={onClickButton}>
                        <a className={bl('button', { disabled: disabledButton })} href={url}>
                            {hasAction ? msgStr('genericAction') : msgStr('backToTheMain')}
                        </a>
                    </div>
                )}
            </div>
        );
    }
);
