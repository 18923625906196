import { FC } from 'react';

import { SupportChatMount } from './__mount/support-chat__mount';
import { SupportChatTrigger } from './__trigger/support-chat__trigger';

export type SupportChatProps = {
    containerId: string;
    isOpen: boolean;
    hasUnreadMessages: boolean;
    showTrigger: boolean;
    onTriggerClick: () => void;
    onClose?: () => void;
    onMountRerender?: () => void;
};

export const SupportChat: FC<SupportChatProps> = ({
    showTrigger,
    hasUnreadMessages,
    isOpen,
    containerId,
    onTriggerClick,
    onClose,
    onMountRerender,
}) => {
    return (
        <>
            {showTrigger && (
                <SupportChatTrigger isOpen={isOpen} hasUnreadMessages={hasUnreadMessages} onClick={onTriggerClick} />
            )}
            <SupportChatMount id={containerId} isOpen={isOpen} onMountRerender={onMountRerender} onClose={onClose} />
        </>
    );
};
