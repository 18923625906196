import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconTick = (props: SVGAttributes<SVGAElement>) => (
    <Icon fill="none" width="12" height="10" viewBox="0 0 12 10" {...props} name="tick">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.68.767a1 1 0 0 1 .053 1.413l-6.5 7a1 1 0 0 1-1.486-.021l-3.5-4a1 1 0 1 1 1.506-1.317l2.77 3.165L10.266.82a1 1 0 0 1 1.414-.053z"
            fill="currentColor"
        />
    </Icon>
);
