import b from 'b_';
import type { KcContextBase, KcProps } from 'keycloakify';
import { memo } from 'react';
import { Input } from 'ui-components/input/input';
import { getMsg } from 'utils/kc-messages';
import { useFieldMessage } from 'utils/use-field-message';
import { useFormValidator } from 'utils/use-form-validator';
import { useSavedFieldLs } from 'utils/use-saved-field-ls';

import { Form } from '../../form/form';
import './login-reset-password.css';

export const LoginResetPassword = memo(({ kcContext }: { kcContext: KcContextBase.LoginResetPassword } & KcProps) => {
    const bl = b.lock('login-reset-password');

    const { url } = kcContext;
    const { msgStr, advancedMsgStr } = getMsg(kcContext);

    const [onChangeSavedField, getSavedField] = useSavedFieldLs();

    const emailError = useFieldMessage(['email', 'username'], kcContext);
    const [formErrors, resetFieldError, onSubmitValidated, submitDisabled] = useFormValidator(['username'], {
        username: emailError,
    });

    const reddirectToLogin = () => {
        window.location.href = url.loginUrl;
    };

    return (
        <Form
            action={url.loginAction}
            kcContext={kcContext}
            title={msgStr('resetPasswordTitle')}
            onSubmit={onSubmitValidated}
        >
            <Input
                type="text"
                id="email"
                name="username"
                label={msgStr('email')}
                autoComplete="email"
                autoFocus={!emailError}
                errorMessage={advancedMsgStr(formErrors['username'])}
                onFocus={resetFieldError}
                onChange={onChangeSavedField}
                defaultValue={getSavedField('username')}
            />

            <Input
                type="submit"
                value={msgStr('resetPasswordButton')}
                disabled={submitDisabled}
            />

            <div className={bl('back-to-login')} onClick={reddirectToLogin}>
                {msgStr('backToLogin')}
            </div>
        </Form>
    );
});
