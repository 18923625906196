import b from 'b_';
import { Logo } from "ui-components/logo/logo";

import './header.css';

export const Header = () => {
    const bl = b.lock('header');

    return (
        <header className={bl()}>
            <Logo className={bl('logo')} />
        </header>
    );
}