import b from 'b_';
import type { KcContextBase, KcProps } from 'keycloakify';
import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Input } from 'ui-components/input/input';
import { getMsg } from 'utils/kc-messages';

import { IconCheckCircleFilled24 } from '../../../icons/symbols/check-circle-filled/icon-check-circle-filled-24';
import { IconCopy24 } from '../../../icons/symbols/copy/icon-copy-24';
import { Form } from '../../form/form';

import './login-config-totp.css';

const otpRegex = /^\d{0,6}$/;
export const LoginConfigTotp = memo(({ kcContext }: { kcContext: KcContextBase.LoginConfigTotp } & KcProps) => {
    const { url, totp, mode, messagesPerField } = kcContext;

    const { msgStr } = getMsg(kcContext);
    const bl = b.lock('login-config-totp');

    const [otp, setOtp] = useState('');
    const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target) {
            return;
        }
        const value = e.target.value;
        if (!otpRegex.test(value)) {
            return;
        }
        setOtp(value);
    }, []);

    const [copied, setCopied] = useState(false);

    const copyCode = useCallback(async () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
        await navigator.clipboard.writeText(totp.totpSecretEncoded);
    }, [totp.totpSecretEncoded]);

    const errorMessage = messagesPerField.existsError('totp') ? msgStr('loginTotpCodeError') : undefined;
    // const { sendGtmEvent } = useAnalytics();
    return (
        <Form action={url.loginAction} kcContext={kcContext} title={msgStr('loginTotpTitle')}>
            <div className={bl('text-block')}>
                {mode && mode === 'manual' ? (
                    <div className={bl('prompt')}>{msgStr('loginTotpSetupKey')}</div>
                ) : (
                    <div className={bl('prompt')}>{msgStr('loginTotpSetupScan')}</div>
                )}
                <div className={bl('note')}>{msgStr('loginTotpTip')}</div>
            </div>

            {mode && mode === 'manual' ? (
                <>
                    <div className={bl('setup-code-block')}>
                        <span className={bl('setup-code')}>{totp.totpSecretEncoded}</span>
                        <span className={bl('separator')}></span>
                        {copied ? (
                            <button className={bl('copy-button')} disabled type="button">
                                <IconCheckCircleFilled24 className={bl('copy-icon')} />
                                {msgStr('loginTotpSetupKeyCopied')}
                            </button>
                        ) : (
                            <button className={bl('copy-button')} onClick={copyCode} type="button" tabIndex={0}>
                                <IconCopy24 className={bl('copy-icon')} />
                                {msgStr('loginTotpSetupKeyCopy')}
                            </button>
                        )}
                    </div>
                    <div className={bl('text-block')}>
                        <a href={totp.qrUrl} className={bl('action-link')} tabIndex={0}>
                            {msgStr('loginTotpScanPossible')}
                        </a>
                    </div>
                </>
            ) : (
                <>
                    <img
                        id="kc-totp-secret-qr-code"
                        className={bl('qr-code')}
                        src={`data:image/png;base64, ${totp.totpSecretQrCode}`}
                        alt="Figure: Qr-code"
                    />

                    <div className={bl('text-block')}>
                        <a href={totp.manualUrl} className={bl('action-link')} tabIndex={0}>
                            {msgStr('loginTotpScanTrouble')}
                        </a>
                    </div>
                </>
            )}
            <div className={bl('text-block')}>
                <div className={bl('prompt-code')}>{msgStr('loginTotpEnterCode')}</div>
            </div>

            <Input
                type="text"
                maxLength={6}
                tabIndex={0}
                id="totp"
                name="totp"
                label={msgStr('loginTotpCode')}
                autoComplete="off"
                autoFocus
                value={otp}
                onChange={onChangeHandler}
                aria-invalid={Boolean(errorMessage)}
                errorMessage={errorMessage}
            />

            <input type="hidden" id="totpSecret" name="totpSecret" value={totp.totpSecret} />
            {mode && <input type="hidden" id="mode" value={mode} />}

            <Input tabIndex={0} type="submit" value={msgStr('loginTotpSubmit')} disabled={otp.length < 6} />
        </Form>
    );
});
