export const SupportChatIconChat = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
            <path
                fill="#000"
                fillOpacity=".83"
                fillRule="evenodd"
                d="M13 11a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h2v3a1 1 0 0 0 1.555.832L22.303 26H27a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H13Zm-1 3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-5a1 1 0 0 0-.555.168L17 27.13V25a1 1 0 0 0-1-1h-3a1 1 0 0 1-1-1v-9Zm4 6.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
};
