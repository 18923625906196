import { CSSProperties, FC, useCallback } from 'react';

import { DrawerBackdrop } from './__backdrop/drawer__backdrop';
import { DrawerCurtain } from './__curtain/drawer__curtain';
import { cnDrawer } from './drawer.constants';

import './drawer.css';

export type DrawerProps = {
    children?: React.ReactNode;
    isOpen: boolean;
    contentId?: string;
    shouldUnmountContent?: boolean;
    onClose?: () => void;
};

const transitionTimingMs = 400;

export const Drawer: FC<DrawerProps> = ({ children, isOpen, contentId, shouldUnmountContent = true, onClose }) => {
    const onCloseClick = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const style = {
        '--transition-timing': `${transitionTimingMs}ms`,
    } as CSSProperties;

    return (
        <div className={cnDrawer()} style={style}>
            <DrawerBackdrop isOpen={isOpen} onClick={onCloseClick} transitionTiming={transitionTimingMs} />

            <DrawerCurtain
                id={contentId}
                isOpen={isOpen}
                transitionTiming={transitionTimingMs}
                shouldUnmount={shouldUnmountContent}
            >
                {children}
            </DrawerCurtain>
        </div>
    );
};
