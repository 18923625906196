import b from 'b_';
import { MessageToast } from 'kc-app/message-toast/message-toast';
import { OpenGmailButton } from 'kc-app/open-gmail-button/open-gmail-button';
import { useShowGmailButton } from 'kc-app/open-gmail-button/use-show-gmail-button';
import { KcProps } from 'keycloakify';
import { memo, useCallback, useState } from 'react';
import { useCssAndCx } from 'tss-react';
import { getMsg } from 'utils/kc-messages';
import { reddirectToSameUrlWithoutTab } from 'utils/reddirect-to-same-url-without-tab';
import { useAnalytics } from 'utils/use-analytics';
import { useShowMailSentToast } from 'utils/use-mail-sent';

import { Form } from '../../form/form';
import { KcContext_MagicLinkForm } from '../../kc-context';

import './magic-link-form.css';

export const MagicLinkForm = memo(({ kcContext }: { kcContext: KcContext_MagicLinkForm } & KcProps) => {
    const { showMailSentToast, setShowMailSentToast, setWasMailSent } = useShowMailSentToast();
    const { url, auth } = kcContext;
    const showGmailButton = useShowGmailButton(auth.attemptedUsername || '');
    const { msgStr } = getMsg(kcContext);
    const bl = b.lock('magic-link-form');
    const { cx } = useCssAndCx();

    const { sendGtmEvent } = useAnalytics();
    const handleResendClick = useCallback(() => {
        sendGtmEvent('resend_link_verify_button_click');
        setWasMailSent('true');
    }, [sendGtmEvent, setWasMailSent]);

    return (
        <Form action={url.loginAction} kcContext={kcContext} className={bl()}>
            {showMailSentToast && (
                <MessageToast message={msgStr('emailResent')} onClose={() => setShowMailSentToast(false)} />
            )}

            <div className={bl('text-block')}>
                <span className={bl('title')}>{msgStr('emailSentMessageStart')} </span>
                <span className={cx(bl('title', { email: true }))}>
                    {auth.attemptedUsername || msgStr('yourEmail')}
                </span>
                <span className={bl('title')}> {msgStr('emailSentMessageLogIn')}</span>
            </div>

            {showGmailButton && (
                <div className={bl('text-block')}>
                    <OpenGmailButton message={msgStr('openGmail')} />
                </div>
            )}

            <div className={bl('text-block')}>
                <span className={bl('note')}>{msgStr('emailVerifyInstruction2') + ' '}</span>
                <button
                    className={bl('action-text')}
                    type="submit"
                    value="resendMagicLink"
                    name="submitAction"
                    onClick={handleResendClick}
                >
                    <span className={bl('note', { accent: true })}>{msgStr('magicLinkResend')}</span>
                </button>
            </div>

            <div
                className={bl('text-block', {
                    'withot-margin': true,
                })}
            >
                <span
                    onClick={reddirectToSameUrlWithoutTab}
                    className={bl('note', {
                        accent: true,
                    })}
                >
                    {msgStr('emailVerifyInstructionCancel')}
                </span>
            </div>
        </Form>
    );
});
