import b from 'b_';
import { Icons } from 'icons/services/services';
import type { KcContextBase } from 'keycloakify';
import type { MouseEventHandler } from 'react';
import { useAnalytics } from 'utils/use-analytics';

import './social-buttons.css';

export interface SocialButtonsProps {
    social: KcContextBase.Login['social'];
    buttomTitle: string;
    onSocialClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const SocialButtons = ({ social, buttomTitle, onSocialClick }: SocialButtonsProps) => {
    const bl = b.lock('social-buttons');
    const { sendGtmEvent } = useAnalytics();

    if (social.providers === undefined) {
        return null;
    }

    return (
        <div className={bl()}>
            {social.providers.map(p => {
                const Icon = Icons[p.alias];

                const handleClick: MouseEventHandler<HTMLAnchorElement> = event => {
                    sendGtmEvent(`${p.providerId}_login_button_click`);

                    if (onSocialClick) onSocialClick(event);
                };

                return (
                    <a key={p.providerId} href={p.loginUrl} className={bl('item')} onClick={handleClick}>
                        <div className={bl('item-wrapper')}>
                            {Icon && <Icon className={bl('item-icon')} />}
                            <span className={bl('item-text')}>{`${buttomTitle} ${p.displayName}`}</span>
                        </div>
                    </a>
                );
            })}
        </div>
    );
};
