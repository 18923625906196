import b from 'b_';

import './open-gmail-button.css';

type OpenGmailButtonProps = {
    message: string;
};

export const OpenGmailButton = ({ message }: OpenGmailButtonProps) => {
    const bl = b.lock('open-gmail-button');

    return (
        <a href="https://mail.google.com/" target='_blank' className={bl()}>
            <GoogleIcon />
            <span>{message}</span>
        </a>
    );
};

const GoogleIcon = () => (
    <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.04594 13.669L7.489 15.7482L5.45341 15.7912C4.84506 14.6629 4.5 13.3719 4.5 12C4.5 10.6735 4.82263 9.42245 5.3945 8.32092H5.39494L7.20719 8.65317L8.00106 10.4545C7.83491 10.939 7.74434 11.459 7.74434 12C7.74441 12.5873 7.85078 13.15 8.04594 13.669Z"
            fill="#FBBB00"
        />
        <path
            d="M20.3601 10.5055C20.452 10.9894 20.4999 11.4892 20.4999 12C20.4999 12.5727 20.4397 13.1314 20.325 13.6703C19.9355 15.5042 18.9179 17.1055 17.5083 18.2387L17.5078 18.2382L15.2252 18.1218L14.9022 16.1051C15.8375 15.5565 16.5685 14.698 16.9536 13.6703H12.6758V10.5055H17.016H20.3601Z"
            fill="#518EF8"
        />
        <path
            d="M17.5076 18.2383L17.5081 18.2387C16.1371 19.3407 14.3955 20 12.4997 20C9.45312 20 6.80434 18.2972 5.45312 15.7913L8.04566 13.6691C8.72125 15.4721 10.4606 16.7557 12.4997 16.7557C13.3762 16.7557 14.1973 16.5187 14.9019 16.1051L17.5076 18.2383Z"
            fill="#28B446"
        />
        <path
            d="M17.6064 5.84175L15.0148 7.9635C14.2855 7.50769 13.4235 7.24437 12.5 7.24437C10.4148 7.24437 8.64288 8.58678 8.00113 10.4545L5.39497 8.32088H5.39453C6.72597 5.75384 9.40816 4 12.5 4C14.4411 4 16.2209 4.69144 17.6064 5.84175Z"
            fill="#F14336"
        />
    </svg>
);
