import { FC, HTMLAttributes, useEffect } from 'react';
import { Drawer } from 'ui-components/drawer/drawer';

import { cnSupportChat } from '../support-chat.constants';
import { useIsMobile } from '../support-chat.hooks/use-is-mobile';

import './_open/support-chat__mount_open.css';
import './support-chat__mount.css';

export type SupportChatMountProps = {
    isOpen: boolean;
    onMountRerender?: () => void;
    onClose?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const SupportChatMount: FC<SupportChatMountProps> = ({ id, isOpen, onMountRerender, onClose }) => {
    const isMobile = useIsMobile();

    useEffect(() => {
        onMountRerender?.();
    }, [isMobile, onMountRerender]);

    if (isMobile) {
        return <Drawer isOpen={isOpen} contentId={id} shouldUnmountContent={false} onClose={onClose} />;
    }

    return <div className={cnSupportChat('mount', { open: isOpen })} id={id}></div>;
};
