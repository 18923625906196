import { useCallback, useEffect, useState } from "react";

export const useShowMailSentToast = () => {
    const [showMailSentToast, setShowMailSentToast] = useState(false);

    useEffect(() => {
        const wasMailSent = sessionStorage.getItem('wasMailSent');
        if (wasMailSent) {
            setShowMailSentToast(true);
            sessionStorage.removeItem('wasMailSent');
        }
    }, []);

    const setWasMailSent = useCallback((value: string) => {
        sessionStorage.setItem('wasMailSent', value);
    }, []);

    return {
        showMailSentToast,
        setShowMailSentToast,
        wasMailSent: sessionStorage.getItem('wasMailSent'),
        setWasMailSent,
    };
};