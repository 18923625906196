import b from 'b_';
import { KcContextBase, KcProps } from 'keycloakify';
import { memo, useCallback, useState } from 'react';
import { Input } from 'ui-components/input/input';
import { PasswordInput } from 'ui-components/password-input/password-input';
import { getMsg } from 'utils/kc-messages';
import { useFieldMessage } from 'utils/use-field-message';
import { useFormValidator } from 'utils/use-form-validator';
import { usePasswordStrengthCriterias } from 'utils/use-password-strength-criterias';

import { Form } from '../../form/form';

export const LoginUpdatePassword = memo(({ kcContext }: { kcContext: KcContextBase.LoginUpdatePassword } & KcProps) => {
    const { url } = kcContext;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Just beleive me. It is custom field
    const isMigration = kcContext.is_migration as boolean;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Just beleive me. It is custom field
    const isFastRegistration = kcContext.is_fast_registration as boolean;

    const { msgStr, advancedMsgStr } = getMsg(kcContext);

    const passwordError = useFieldMessage(['password', 'password-new', 'password-confirm'], kcContext);

    let buttonText = msgStr('updatePasswordButton');
    buttonText = isFastRegistration ? msgStr('updatePasswordFastRegistrationButton') : buttonText;

    const setPasswordField = useCallback((formElement: HTMLFormElement) => {
        if (formElement['password-new'] && formElement['password-confirm']) {
            (formElement['password-confirm'] as HTMLInputElement).value = (
                formElement['password-new'] as HTMLInputElement
            ).value;

            return true;
        }

        return false;
    }, []);

    const [formErrors, resetFieldError, onSubmitValidated, submitDisabled] = useFormValidator(
        ['password-new'],
        {
            password: passwordError,
        },
        setPasswordField
    );

    const [password, setPassword] = useState('');

    const handlePasswordChange = useCallback(e => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!e.target) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setPassword((e.target as HTMLInputElement).value);
    }, []);

    const passwordStrengthLevels = usePasswordStrengthCriterias(msgStr);

    return (
        <Form title={msgStr('updatePasswordTitle')} action={url.loginAction} kcContext={kcContext} onSubmit={onSubmitValidated}>
            {isMigration && (
                <>
                    <div className={b('form', 'message')}>
                        <span>{msgStr('updatePasswordMigrationMessage')}</span>
                    </div>

                    <div className={b('form', 'note')}>{msgStr('updatePasswordMigrationNote')}</div>
                </>
            )}
            <PasswordInput
                className={b('password-strength')}
                id="password-new"
                name="password-new"
                errorMessage={advancedMsgStr(formErrors['password-new'])}
                hintText={msgStr('passwordStrengthHint')}
                label={msgStr('password')}
                onChange={handlePasswordChange}
                onFocus={resetFieldError}
                password={password}
                strengthLevels={passwordStrengthLevels}
                value={password}
                autoFocus={!passwordError}
            />

            <Input type="submit" value={buttonText} disabled={submitDisabled} />
        </Form>
    );
});
