import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconFacebook24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon viewBox="0 0 24 24" fill="none" name="facebook-24" {...props}>
        <path
            d="M12 3.89099C16.476 3.89099 20.1036 7.5186 20.1036 11.9945C20.1036 16.04 17.1407 19.3922 13.2662 20V14.337H15.1544L15.5137 11.9945H13.2662V10.4751C13.2662 9.83413 13.5796 9.20895 14.5862 9.20895H15.6087V7.21472C15.6087 7.21472 14.6812 7.05644 13.7949 7.05644C11.9431 7.05644 10.7339 8.1786 10.7339 10.2092V11.9945H8.67632V14.337H10.7339V20C6.85935 19.3922 3.89648 16.04 3.89648 11.9945C3.89648 7.5186 7.52409 3.89099 12 3.89099Z"
            fill="#1877F2"
        />
    </Icon>
);
