import b from 'b_';
import { IconTick } from 'icons/symbols/tick/tick';
import type { HTMLProps } from 'react';
import { SlideDown } from 'react-slidedown';
import { useCssAndCx } from 'tss-react';

import './checkbox.css';

interface CheckboxProps {
    errorMessage?: string;
    childrenWrapperClassName?: string;
}

export const Checkbox = (props: HTMLProps<HTMLInputElement> & CheckboxProps) => {
    const { cx } = useCssAndCx();
    const {
        className,
        childrenWrapperClassName,
        onChange,
        checked,
        children,
        disabled,
        value,
        name,
        errorMessage,
        ...restProps
    } = props;
    const isError = !!errorMessage;

    return (
        <div className="checkbox-wrapper">
            <label className={cx(b('checkbox', { disabled }), className)}>
                <input
                    name={name}
                    checked={checked}
                    value={value}
                    className="checkbox__input"
                    disabled={disabled}
                    onChange={onChange}
                    type="checkbox"
                    {...restProps}
                />
                <div className="checkbox__control">
                    <IconTick className="checkbox__tick" />
                </div>
                <div className={cx(b('checkbox', 'label'), childrenWrapperClassName)}>{children}</div>
            </label>
            <SlideDown className={cx('checkbox__message-slidedown')} transitionOnAppear={false} closed={!isError}>
                {isError && <div className={b('checkbox', 'message', { error: isError })}>{errorMessage}</div>}
            </SlideDown>
        </div>
    );
};
