import { useEffect } from 'react';

export const useEmbedHubspotScript = (chatFlowId: string, containerId: string) => {
    useEffect(() => {
        window.hsConversationsSettings = {
            loadImmediately: false,
            inlineEmbedSelector: `#${containerId}`,
        };

        window.__hsConversationsOnReadyPromise = new Promise(resolve => {
            window.hsConversationsOnReady = [resolve];
        });

        const script = document.createElement('script');

        script.src = `//js-eu1.hs-scripts.com/${chatFlowId}.js`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [chatFlowId, containerId]);
};
