import { FC, HTMLAttributes, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import { cnDrawer } from '../drawer.constants';

import './../__content/drawer__content.css';
import './_open/drawer__curtain_open.css';
import './drawer__curtain.css';

export type DrawerCurtainProps = {
    children: ReactNode;
    isOpen: boolean;
    shouldUnmount: boolean;
    transitionTiming: number;
} & HTMLAttributes<HTMLDivElement>;

export const DrawerCurtain: FC<DrawerCurtainProps> = ({ children, id, isOpen, shouldUnmount, transitionTiming }) => {
    return (
        <CSSTransition in={isOpen} timeout={transitionTiming} unmountOnExit={shouldUnmount}>
            <div className={cnDrawer('curtain', { open: isOpen })}>
                <div id={id} className={cnDrawer('content', { open: isOpen })}>
                    {children}
                </div>
            </div>
        </CSSTransition>
    );
};
