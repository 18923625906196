export const SupportChatIconClose = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path
                fill="#000"
                fillOpacity=".83"
                d="M6.64 5.226a1 1 0 1 0-1.414 1.415l5.36 5.36-5.36 5.359a1 1 0 1 0 1.415 1.414l5.36-5.36 5.359 5.36a1 1 0 1 0 1.414-1.414L13.414 12l5.36-5.36a1 1 0 1 0-1.414-1.414L12 10.586l-5.36-5.36Z"
            />
        </svg>
    );
};
