import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconCheckCircleFilled24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon width={24} height={24} type="objects-copy-24" {...props}>
        <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12ZM17.6644 7.75259C17.2516 7.38567 16.6195 7.42285 16.2526 7.83564L10.9861 13.7605L8.73485 11.3213C8.36027 10.9155 7.72761 10.8902 7.32177 11.2647C6.91592 11.6393 6.89057 12.272 7.26515 12.6778L9.89076 15.5226C10.4526 16.1314 11.4016 16.1694 12.0104 15.6075C12.0372 15.5819 12.0506 15.5691 12.0636 15.5559C12.0765 15.5427 12.0891 15.5291 12.1141 15.5018L17.7474 9.16436C18.1143 8.75158 18.0771 8.11951 17.6644 7.75259Z"
            fill="currentColor"
        />
    </Icon>
);
