import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconSymbolsCross24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon viewBox="0 0 24 24" fill="none" name="symbols-24-cross" {...props}>
        <path
            d="M6.64046 5.22624C6.24993 4.83572 5.61677 4.83572 5.22624 5.22624C4.83572 5.61677 4.83572 6.24993 5.22624 6.64046L10.5858 12L5.22624 17.3596C4.83572 17.7501 4.83572 18.3833 5.22624 18.7738C5.61677 19.1643 6.24993 19.1643 6.64046 18.7738L12 13.4142L17.3596 18.7738C17.7501 19.1643 18.3833 19.1643 18.7738 18.7738C19.1643 18.3833 19.1643 17.7501 18.7738 17.3596L13.4142 12L18.7738 6.64046C19.1643 6.24993 19.1643 5.61677 18.7738 5.22624C18.3833 4.83572 17.7501 4.83572 17.3596 5.22624L12 10.5858L6.64046 5.22624Z"
            fill="currentColor"
            fillOpacity="0.85"
        />
    </Icon>
);
