import { FocusEvent, FormEvent, MouseEvent, useCallback, useState } from 'react';
import type { Validator } from 'utils/validation-rules';
import {
    emailValidator,
    hasLowerCaseCharValidator,
    hasNumberValidator,
    hasUpperCaseCharValidator,
    lengthValidator,
    notEmptyValidator,
    privacyPolicyValidator,
} from 'utils/validation-rules';

export type FormErrorKey =
    | 'formErrorCannotBeEmpty'
    | 'formErrorAcceptPrivacyPolicy'
    | 'formErrorWrongEmailFormat'
    | 'formErrorPasswordIsTooShort'
    | 'formErrorPasswordShouldHaveUpperCase'
    | 'formErrorPasswordShouldHaveLowerCase'
    | 'formErrorPasswordShouldHaveNumber';

interface FieldValidator {
    validate: Validator;
    errorKey: FormErrorKey;
}

const fieldValidators: Record<string, FieldValidator[]> = {
    firstName: [
        {
            validate: notEmptyValidator,
            errorKey: 'formErrorCannotBeEmpty',
        },
    ],
    email: [
        {
            validate: notEmptyValidator,
            errorKey: 'formErrorCannotBeEmpty',
        },
        {
            validate: emailValidator,
            errorKey: 'formErrorWrongEmailFormat',
        },
    ],
    password: [
        {
            validate: notEmptyValidator,
            errorKey: 'formErrorCannotBeEmpty',
        },
        {
            validate: lengthValidator,
            errorKey: 'formErrorPasswordIsTooShort',
        },
        {
            validate: hasUpperCaseCharValidator,
            errorKey: 'formErrorPasswordShouldHaveUpperCase',
        },
        {
            validate: hasLowerCaseCharValidator,
            errorKey: 'formErrorPasswordShouldHaveLowerCase',
        },
        {
            validate: hasNumberValidator,
            errorKey: 'formErrorPasswordShouldHaveNumber',
        },
    ],
    'password-new': [
        {
            validate: notEmptyValidator,
            errorKey: 'formErrorCannotBeEmpty',
        },
        {
            validate: lengthValidator,
            errorKey: 'formErrorPasswordIsTooShort',
        },
        {
            validate: hasUpperCaseCharValidator,
            errorKey: 'formErrorPasswordShouldHaveUpperCase',
        },
        {
            validate: hasLowerCaseCharValidator,
            errorKey: 'formErrorPasswordShouldHaveLowerCase',
        },
        {
            validate: hasNumberValidator,
            errorKey: 'formErrorPasswordShouldHaveNumber',
        },
    ],
    privacyPolicy: [
        {
            validate: privacyPolicyValidator,
            errorKey: 'formErrorAcceptPrivacyPolicy',
        },
    ],
    username: [
        {
            validate: notEmptyValidator,
            errorKey: 'formErrorCannotBeEmpty',
        },
    ],
};

type AvailableField = keyof typeof fieldValidators;
type ErrorMessages = Partial<Record<AvailableField, string>>;

export const useFormValidator = (
    fields: AvailableField[],
    serverErrors?: ErrorMessages,
    additionalCallback?: (formElement: HTMLFormElement) => boolean // возвращает значение, нужно ли выходить из функции, не вызывая функцию submit у формы
): [
    formErrors: ErrorMessages,
    resetFieldError: (event: FocusEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => void,
    onSubmitValidated: (event: FormEvent<HTMLFormElement>) => void,
    submitDisabled: boolean,
    setFormErrors: (newFormErrors: ErrorMessages) => void
] => {
    const [formErrors, setFormErrors] = useState<ErrorMessages>(serverErrors || {});
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const resetFieldError = useCallback(
        (event: FocusEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
            const inputElem = event.target as HTMLInputElement;
            const fieldName = inputElem.name;
            if (formErrors[fieldName]) {
                setFormErrors({ ...formErrors, [fieldName]: '' });
            }
        },
        [formErrors]
    );

    const validate = useCallback(
        (formElement: HTMLFormElement) => {
            const formData = new FormData(formElement);
            const formValues: Record<string, FormDataEntryValue> = {};
            formData.forEach((v, k) => {
                formValues[k] = v;
            });

            const newFormErrors: ErrorMessages = {};
            fields.forEach(field => {
                const key = field;
                newFormErrors[key] = ''; // reset previous error
                const validators = fieldValidators[key];
                const value = formValues[key];
                if (validators?.length > 0 && (typeof value === 'string' || typeof value === 'undefined')) {
                    newFormErrors[key] = validators.reduce((error, fieldValidator) => {
                        return error || (fieldValidator.validate(value) ? '' : fieldValidator.errorKey);
                    }, '');
                }
            });

            setFormErrors(newFormErrors);

            const isFormHasErrors = Object.values(newFormErrors).reduce(
                (hasErrors, error) => hasErrors || (error || '').length > 0,
                false
            );

            return !isFormHasErrors;
        },
        [fields]
    );

    const onSubmitValidated = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            let isValid = true;
            const formElement = event.target as HTMLFormElement;

            if (formElement) {
                if (validate(formElement) !== true) {
                    isValid = false;
                }

                if (additionalCallback) {
                    isValid = additionalCallback(formElement) && isValid;
                }

                if (isValid) {
                    setSubmitDisabled(true);

                    formElement.submit();
                }
            }
        },
        [additionalCallback, validate]
    );

    return [formErrors, resetFieldError, onSubmitValidated, submitDisabled, setFormErrors];
};
