import { SVGAttributes } from 'react';
import { useCssAndCx } from 'tss-react';

import './icon.css';

export const Icon = ({
    className,
    name,
    width = 24,
    height = 24,
    children,
    ...restProps
}: SVGAttributes<SVGElement>) => {
    const { cx } = useCssAndCx();
    const strWidth = width.toString();
    const strHeight = height.toString();

    return (
        <svg
            className={cx(`icon ${name !== undefined ? `icon-${name}` : ''}`, className)}
            width={`${strWidth}`}
            height={`${strHeight}`}
            viewBox={`0 0 ${strWidth} ${strHeight}`}
            {...restProps}
        >
            {children}
        </svg>
    );
};
