import { FC, useCallback, useEffect, useState } from 'react';
import { SupportChat } from 'ui-components/support-chat/support-chat';

import { useChatReady } from './hubspot.hooks/use-chat-ready';
import { useEmbedHubspotScript } from './hubspot.hooks/use-embed-hubspot-script';
import { useHasUnreadMessages } from './hubspot.hooks/use-has-unread-messages';
import { useWidgetLoaded } from './hubspot.hooks/use-widget-loaded';

import './hubspot.css';

export type HubspotProps = {
    chatFlowId: string;
    containerId: string;
};

export const Hubspot: FC<HubspotProps> = ({ chatFlowId, containerId }) => {
    useEmbedHubspotScript(chatFlowId, containerId);

    const isChatReady = useChatReady();
    const isWidgetLoaded = useWidgetLoaded(isChatReady);
    const { hasUnreadMessages, setHasUnreadMessages } = useHasUnreadMessages(isChatReady);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setHasUnreadMessages(false);
        }
    }, [isOpen, hasUnreadMessages, setHasUnreadMessages]);

    const reloadWidget = useCallback(() => {
        window.HubSpotConversations?.widget.remove();
        window.HubSpotConversations?.widget.load();
    }, []);

    if (!isChatReady) {
        return null;
    }

    return (
        <SupportChat
            showTrigger={isWidgetLoaded}
            containerId={containerId}
            hasUnreadMessages={hasUnreadMessages}
            isOpen={isOpen}
            onTriggerClick={() => setIsOpen(!isOpen)}
            onClose={() => setIsOpen(false)}
            onMountRerender={reloadWidget}
        />
    );
};
