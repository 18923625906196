import b from 'b_';
import { KcContextBase, KcProps } from 'keycloakify';
import {
    ChangeEventHandler,
    FocusEvent,
    memo,
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Checkbox } from 'ui-components/checkbox/checkbox';
import { Input } from 'ui-components/input/input';
import { PasswordInput } from 'ui-components/password-input/password-input';
import { getMsg } from 'utils/kc-messages';
import { useAnalytics } from 'utils/use-analytics';
import { useB2bCookie } from 'utils/use-b2b-cookie';
import { useFieldMessage } from 'utils/use-field-message';
import { useFormValidator } from 'utils/use-form-validator';
import { usePasswordStrengthCriterias } from 'utils/use-password-strength-criterias';
import { useSavedFieldLs } from 'utils/use-saved-field-ls';
import { useTriedToLoginWithNonExistendEmail } from 'utils/use-tried-to-login-with-non-existend-email';

import { Form } from '../../form/form';

import './register.css';

export const Register = memo(({ kcContext }: { kcContext: KcContextBase.Register } & KcProps) => {
    const { url, register, passwordRequired } = kcContext;

    const [onChangeSavedField, getSavedField] = useSavedFieldLs();

    useB2bCookie();

    const { msgStr, advancedMsgStr } = getMsg(kcContext);

    const emailError = useFieldMessage(['email', 'username'], kcContext);
    const passwordError = useFieldMessage(['password', 'password-confirm'], kcContext);

    // redirect to login page if user didn't come from login page
    // i.e. user opened registration page directly
    useEffect(() => {
        const email = getSavedField('email');
        if (!email) {
            window.location.href = url.loginUrl;
        }
    }, [getSavedField, url.loginUrl]);

    const saveEmail = useCallback((formElement: HTMLFormElement) => {
        if (formElement['email']) {
            const currentEmail: string = (formElement['email'] as HTMLInputElement).value ?? '';
            sessionStorage.setItem('userEmail', currentEmail);
        }

        return true;
    }, []);

    const [formErrors, resetFieldError, onSubmitValidated, submitDisabled, setFormErrors] = useFormValidator(
        ['firstName', 'email', 'password', 'privacyPolicy'],
        { email: emailError, password: passwordError },
        saveEmail
    );

    const handleSubmition: React.FormEventHandler<HTMLFormElement> = useCallback(
        e => {
            onSubmitValidated(e);
        },
        [onSubmitValidated]
    );

    const [password, setPassword] = useState('');

    const handlePasswordChange = useCallback(e => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!e.target) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setPassword((e?.target as HTMLInputElement).value);
    }, []);

    const formRef = useRef<HTMLFormElement | null>(null);

    const onSocialClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
        e => {
            if (!formRef.current) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (formRef.current.privacyPolicy.checked) {
                return;
            }

            e.preventDefault();

            setFormErrors({ ...formErrors, privacyPolicy: 'formErrorAcceptPrivacyPolicy' });
        },
        [formErrors, setFormErrors]
    );

    const { sendGtmEvent } = useAnalytics();

    const inputFocusHandler = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            const id = e.target.id;
            let event;

            if (id === 'firstName') {
                event = 'registration_form_field_name_input';
            } else if (id === 'email') {
                event = 'registration_form_field_email_input';
            } else if (id === 'password') {
                event = 'registration_form_field_password_input';
            }

            if (event) sendGtmEvent(event);

            resetFieldError(e);
        },
        [resetFieldError, sendGtmEvent]
    );

    const passwordStrengthLevels = usePasswordStrengthCriterias(msgStr);

    const reddirectToLogin = useCallback<ChangeEventHandler<HTMLInputElement>>(
        e => {
            onChangeSavedField(e);
            window.location.href = url.loginUrl;
        },
        [onChangeSavedField, url.loginUrl]
    );

    return (
        <Form
            action={url.registrationAction}
            kcContext={kcContext}
            onSubmit={handleSubmition}
            onSocialClick={onSocialClick}
            ref={formRef}
        >
            <Input
                type="text"
                id="email"
                name="email"
                defaultValue={register.formData.email ?? getSavedField('email')}
                label={msgStr('email')}
                autoComplete="email"
                errorMessage={advancedMsgStr(formErrors['email'])}
                onFocus={inputFocusHandler}
                onChange={reddirectToLogin}
                autoFocus
            />

            <div className={b('register', 'tip-after-reddirect')}>{msgStr('noAccount')}</div>

            <Input
                type="text"
                id="firstName"
                name="firstName"
                defaultValue={register.formData.firstName ?? getSavedField('firstName')}
                label={msgStr('firstName')}
                errorMessage={advancedMsgStr(formErrors['firstName'])}
                onFocus={inputFocusHandler}
                onChange={onChangeSavedField}
            />

            {passwordRequired && (
                <PasswordInput
                    className={b('register', 'password-strength')}
                    errorMessage={advancedMsgStr(formErrors['password'])}
                    hintText={msgStr('passwordStrengthHint')}
                    label={msgStr('password')}
                    onChange={handlePasswordChange}
                    onFocus={inputFocusHandler}
                    password={password}
                    strengthLevels={passwordStrengthLevels}
                    value={password}
                />
            )}

            <Checkbox
                type="checkbox"
                id="privacyPolicy"
                name="privacyPolicy"
                onChange={onChangeSavedField}
                errorMessage={advancedMsgStr(formErrors['privacyPolicy'])}
                onClick={resetFieldError}
                className={b('register', 'privacy-policy-label')}
                childrenWrapperClassName={b('register', 'privacy-policy-label-children-wrapper')}
            >
                {msgStr('accept')}&nbsp;
                <a href={msgStr('tosPageLink')} target="blank" className={b('register', 'privacy-policy-link')}>
                    {msgStr('privacyPolicy')}
                </a>
            </Checkbox>

            <Input type="submit" value={msgStr('doRegister')} disabled={submitDisabled} />
        </Form>
    );
});
