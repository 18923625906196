import { MessageKey as BaseMessageKey, kcMessages } from 'keycloakify';

// https://github.com/garronej/keycloakify-demo-app/blob/main/src/kcMessagesExtension.ts
import translations from './translations/generated-messages.json';

export type MessageKey = keyof typeof translations['en'] | BaseMessageKey;

interface MessageId {
    id: { [TValue: string]: string };
}

type ExtendedKcMessages = typeof kcMessages & MessageId;

Object.assign(kcMessages['en'], translations['en']);
Object.assign(kcMessages['es'], translations['es']);
(kcMessages as ExtendedKcMessages)['id'] = translations['id'];
Object.assign(kcMessages['pt-BR'], translations['pt-BR']);
Object.assign(kcMessages['fr'], translations['fr']);
