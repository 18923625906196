export type Validator = (value?: string) => boolean;

export const notEmptyValidator: Validator = value => {
    return Boolean(value && value.trim().length > 0);
};

export const privacyPolicyValidator: Validator = value => {
    return typeof value !== 'undefined';
};

const SIMPLE_EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const emailValidator: Validator = value => {
    return Boolean(value && SIMPLE_EMAIL_REGEXP.test(value));
};

const MIN_PASSWORD_LENGTH = 8;
export const lengthValidator: Validator = value => {
    return Boolean(value && value.length >= MIN_PASSWORD_LENGTH);
};

export const hasUpperCaseCharValidator: Validator = value => {
    return Boolean(value && value !== value.toLowerCase());
};

export const hasLowerCaseCharValidator: Validator = value => {
    return Boolean(value && value !== value.toUpperCase());
};

export const hasNumberValidator: Validator = value => {
    return Boolean(value && /\d/.test(value));
};
