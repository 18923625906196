import b from 'b_';
import type { KcContextBase, KcProps } from 'keycloakify';
import { ChangeEvent, memo, useCallback, useState } from 'react';
import { Input } from 'ui-components/input/input';
import { getMsg } from 'utils/kc-messages';
import { useAnalytics } from 'utils/use-analytics';

import { Form } from '../../form/form';

import './login-otp.css';

const otpRegex = /^\d{0,6}$/;
export const LoginOtp = memo(({ kcContext }: { kcContext: KcContextBase.LoginOtp } & KcProps) => {
    const { otpLogin, url, messagesPerField } = kcContext;
    const { msgStr } = getMsg(kcContext);
    const bl = b.lock('login-otp');

    const { sendGtmEvent } = useAnalytics();

    const [otp, setOtp] = useState('');
    const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target) {
            return;
        }
        const value = e.target.value;
        if (!otpRegex.test(value)) {
            return;
        }
        setOtp(value);
    }, []);
    return (
        <Form action={url.loginAction} kcContext={kcContext}>
            <div className={bl('text-block')}>
                <span className={bl('prompt')}>{msgStr('loginOtpOneTime')}</span>
            </div>

            <Input
                type="text"
                maxLength={6}
                tabIndex={1}
                id="otp"
                name="otp"
                label={msgStr('loginOtpCode')}
                autoComplete="off"
                autoFocus
                value={otp}
                onChange={onChangeHandler}
            />

            {otpLogin.userOtpCredentials.map(otpCredential => (
                <div key={otpCredential.id}>
                    <Input hidden value={otpCredential.id} />
                </div>
            ))}

            <Input tabIndex={2} type="submit" value={msgStr('loginOtpSubmit')} disabled={otp.length < 6} />
        </Form>
    );
});
