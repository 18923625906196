import b from 'b_';
import { MessageToast } from 'kc-app/message-toast/message-toast';
import { OpenGmailButton } from 'kc-app/open-gmail-button/open-gmail-button';
import { useShowGmailButton } from 'kc-app/open-gmail-button/use-show-gmail-button';
import type { KcContextBase, KcProps } from 'keycloakify';
import { memo, useCallback } from 'react';
import { useCssAndCx } from 'tss-react';
import { getMsg } from 'utils/kc-messages';
import { reddirectToSameUrlWithoutTab } from 'utils/reddirect-to-same-url-without-tab';
import { useAnalytics } from 'utils/use-analytics';
import { useShowMailSentToast } from 'utils/use-mail-sent';

import { Form } from '../../form/form';

import './login-verify-email.css';



export const LoginVerifyEmail = memo(({ kcContext }: { kcContext: KcContextBase.LoginVerifyEmail } & KcProps) => {
    const { showMailSentToast, setShowMailSentToast, setWasMailSent } = useShowMailSentToast();

    const bl = b.lock('login-verify-email');
    const { url } = kcContext;
    const { msgStr } = getMsg(kcContext);
    const { cx } = useCssAndCx();
    const defaultEmail = msgStr('yourEmail');
    const userEmail = sessionStorage.getItem('userEmail') || sessionStorage.getItem('username') || defaultEmail;
    const showGmailButton = useShowGmailButton(userEmail || '');

    const { sendGtmEvent } = useAnalytics();
    const handleResendClick = useCallback(() => {
        sendGtmEvent('resend_link_verify_button_click');
        setWasMailSent('true');
    }, [sendGtmEvent, setWasMailSent]);

    return (
        <Form action={url.loginAction} kcContext={kcContext} className={bl()}>
            <div className={bl('text-block')}>
                <span className={bl('title')}>{msgStr('emailSentMessageStart')} </span>
                <span className={cx(bl('title', { email: true }))}>{userEmail || msgStr('yourEmail')}</span>
                <span className={bl('title')}> {msgStr('emailSentMessageAccountSetup')}</span>
            </div>

            {showGmailButton && (
                <div className={bl('text-block')}>
                    <OpenGmailButton message={msgStr('openGmail')} />
                </div>
            )}

            <div className={bl('text-block')}>
                <span className={bl('note')}>{msgStr('emailVerifyInstruction2') + ' '}</span>
                <button
                    className={bl('action-text')}
                    type="submit"
                    value="resendMagicLink"
                    name="submitAction"
                    onClick={handleResendClick}
                >
                    <span className={bl('note', { accent: true })}>{msgStr('magicLinkResend')}</span>
                </button>
            </div>

            {showMailSentToast && (
                <MessageToast message={msgStr('emailResent')} onClose={() => setShowMailSentToast(false)} />
            )}

            <div
                className={bl('text-block', {
                    'withot-margin': true,
                })}
            >
                <span
                    onClick={reddirectToSameUrlWithoutTab}
                    className={bl('note', {
                        accent: true,
                    })}
                >
                    {msgStr('emailVerifyInstructionCancel')}
                </span>
            </div>
        </Form>
    );
});
