import { forwardRef, HTMLProps, useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import { Input, InputProps } from 'ui-components/input/input';
import {
    PasswordStrengthMeter,
    PasswordStrengthMeterProps,
} from 'ui-components/password-strength-meter/password-strength-meter';

import './password-input.css';

type PasswordInputProps = InputProps & PasswordStrengthMeterProps;

export const PasswordInput = forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement> & PasswordInputProps>(props => {
    const {
        password,
        strengthLevels,
        onChange,
        onFocus,
        label,
        errorMessage,
        hintText,
        id = 'password',
        name = 'password',
    } = props;

    const [hidePasswordStrength, setHidePasswordStrenght] = useState(true);

    useEffect(() => {
        if (password.length > 0 && hidePasswordStrength) {
            setHidePasswordStrenght(false);
        }
    }, [hidePasswordStrength, password]);

    const inputErrorMessage = hidePasswordStrength ? errorMessage : '';

    return (
        <>
            <Input
                type="password"
                id={id}
                name={name}
                label={label}
                errorMessage={inputErrorMessage}
                onFocus={onFocus}
                onChange={onChange}
                value={password}
            />
            <Input type="hidden" id="password-confirm" name="password-confirm" value={password} />

            <SlideDown className="password-strength-slidedown" transitionOnAppear={false} closed={hidePasswordStrength}>
                <PasswordStrengthMeter
                    className={'password-input__strength-meter'}
                    password={password}
                    hintText={hintText}
                    strengthLevels={strengthLevels}
                />
            </SlideDown>
        </>
    );
});
