import { TouchEventHandler, useState } from 'react';

export const useDragDown = (dragThreshold: number, callback: () => void) => {
    const [startY, setStartY] = useState<number | null>(null);

    const onTouchStart: TouchEventHandler<HTMLDivElement> = e => {
        setStartY(e.touches[0].clientY);
    };

    const onTouchMove: TouchEventHandler<HTMLDivElement> = e => {
        if (startY !== null) {
            const currentY = e.touches[0].clientY;
            const distance = currentY - startY;

            if (distance > dragThreshold) {
                callback();
                setStartY(null);
            }
        }
    };

    const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
        setStartY(null);
    };

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
    };
};
