import { KcContext_LoginUsername } from "kc-app/kc-context";
import { KcContextBase } from "keycloakify";

import { useFieldMessage } from "./use-field-message";
import { useTriedToLoginWithNonExistendEmail } from "./use-tried-to-login-with-non-existend-email";

export const useReddirectToSignUp = (kcContext: KcContextBase.Login | KcContext_LoginUsername) => {
    const { url } = kcContext;
    const emailError = useFieldMessage(['email', 'username'], kcContext);
    const triedToLoginWithNonExistendEmail = useTriedToLoginWithNonExistendEmail()

    if(emailError) {
        triedToLoginWithNonExistendEmail.set(true);
        window.location.href = url.registrationUrl;
    }
}
