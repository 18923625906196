import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconGoogle24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon viewBox="0 0 24 24" fill="none" name="google-24" {...props}>
        <path
            d="M7.04594 13.669L6.489 15.7481L4.45341 15.7912C3.84506 14.6628 3.5 13.3719 3.5 12C3.5 10.6734 3.82263 9.42239 4.3945 8.32086H4.39494L6.20719 8.65311L7.00106 10.4545C6.83491 10.9389 6.74434 11.4589 6.74434 12C6.74441 12.5872 6.85078 13.1499 7.04594 13.669Z"
            fill="#FBBB00"
        />
        <path
            d="M19.3601 10.5055C19.452 10.9894 19.4999 11.4892 19.4999 12C19.4999 12.5727 19.4397 13.1314 19.325 13.6703C18.9355 15.5042 17.9179 17.1055 16.5083 18.2387L16.5078 18.2382L14.2252 18.1218L13.9022 16.1051C14.8375 15.5565 15.5685 14.698 15.9536 13.6703H11.6758V10.5055H16.016H19.3601Z"
            fill="#518EF8"
        />
        <path
            d="M16.5079 18.2382L16.5083 18.2387C15.1373 19.3406 13.3958 20 11.5 20C8.45337 20 5.80459 18.2971 4.45337 15.7912L7.0459 13.669C7.72149 15.4721 9.46084 16.7556 11.5 16.7556C12.3764 16.7556 13.1976 16.5187 13.9021 16.105L16.5079 18.2382Z"
            fill="#28B446"
        />
        <path
            d="M16.6064 5.84175L14.0148 7.9635C13.2855 7.50769 12.4235 7.24437 11.5 7.24437C9.41475 7.24437 7.64288 8.58678 7.00113 10.4545L4.39497 8.32088H4.39453C5.72597 5.75384 8.40816 4 11.5 4C13.4411 4 15.2209 4.69144 16.6064 5.84175Z"
            fill="#F14336"
        />
    </Icon>
);
