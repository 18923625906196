import { FC } from 'react';

import { SupportChatIconChat } from '../__icon-chat/support-chat__icon-chat';
import { SupportChatIconClose } from '../__icon-close/support-chat__icon-close';
import { cnSupportChat } from '../support-chat.constants';

import './support-chat__trigger.css';
import './_unread/support-chat__trigger_unread.css';
import './_open/support-chat__trigger_open.css';

export type SupportChatTriggerProps = {
    isOpen: boolean;
    hasUnreadMessages: boolean;
    onClick: () => void;
};

export const SupportChatTrigger: FC<SupportChatTriggerProps> = ({ isOpen, hasUnreadMessages, onClick }) => {
    const isUnread = hasUnreadMessages && !isOpen;

    return (
        <button
            className={cnSupportChat('trigger', { open: isOpen, unread: isUnread })}
            onClick={onClick}
        >
            {!isOpen && <SupportChatIconChat />}
            {isOpen && <SupportChatIconClose />}
        </button>
    );
};
