import b from 'b_';
import type { KcContextBase } from 'keycloakify';
import type { FormEventHandler, MouseEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';
import { useCssAndCx } from 'tss-react';
import { getMsg } from 'utils/kc-messages';

import { KcContext_LoginUsername, KcContext_MagicLinkConfirm, KcContext_MagicLinkForm } from '../kc-context';
import { SocialButtons } from '../social-buttons/social-buttons';

import './form.css';

export interface FormProps {
    action: string;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    kcContext:
        | KcContextBase.LoginVerifyEmail
        | KcContextBase.Register
        | KcContextBase.Login
        | KcContextBase.LoginResetPassword
        | KcContextBase.LoginUpdatePassword
        | KcContextBase.LoginOtp
        | KcContextBase.LoginConfigTotp
        | KcContext_LoginUsername
        | KcContext_MagicLinkConfirm
        | KcContext_MagicLinkForm;
    children: ReactNode;
    className?: string;
    onSocialClick?: MouseEventHandler<HTMLAnchorElement>;
    title?: string;
}

export const Form = forwardRef<HTMLFormElement, FormProps>(
    ({ kcContext, children, className = '', title, ...props }, outerRef) => {
        const bl = b.lock('form');
        const { pageId, client, realm } = kcContext;
        const { cx } = useCssAndCx();

        const isClientHR = client.clientId === 'hr-admin' || client.clientId === 'prestable-hr-admin';
        const isUsernameLoginVisible = realm.displayName !== "internal";

        const isSocialBlockVisible =
            pageId === 'register.ftl' ||
            pageId === 'magic-link-form.ftl' ||
            pageId === 'login.ftl' ||
            pageId === 'login-username.ftl';

        const { msgStr } = getMsg(kcContext);
        const socialButtonTitle = msgStr('socialButtonTitle');
        const loginTitle = title ?? msgStr('loginTitle');

        return (
            <div className={cx(bl(), className)}>
                <h1 className={bl('title')}>{title || loginTitle}</h1>

                {isUsernameLoginVisible && (<form className={bl('content')} method="post" ref={outerRef} {...props}>
                    {children}
                </form>)}

                {isSocialBlockVisible && kcContext.social && !isClientHR && (
                    <div className={bl('content')}>
                        <SocialButtons social={kcContext.social} buttomTitle={socialButtonTitle} {...props} />
                    </div>
                )}
            </div>
        );
    }
);
