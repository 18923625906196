import React from 'react';
import Helmet from 'react-helmet';

interface GoogleTagManagerProps {
    id: string;
}

export const GoogleTagManager = ({ id }: GoogleTagManagerProps) => {
    return (
        <>
            <Helmet>
                <script id="gtmScript">
                    {`window.dataLayer = window.dataLayer || [];
                    function gtm(){dataLayer.push(...arguments);}`}
                </script>
                <script>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
                n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${id}');`}
                </script>
            </Helmet>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            />
        </>
    );
};
