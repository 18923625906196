import { useEffect, useState } from 'react';

export const useHasUnreadMessages = (isChatReady: boolean) => {
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    useEffect(() => {
        if (!isChatReady) {
            return;
        }

        const listener = () => {
            setHasUnreadMessages(true);
        };

        window.HubSpotConversations?.on('unreadConversationCountChanged', listener);

        return () => {
            window.HubSpotConversations?.off('unreadConversationCountChanged', listener);
        };
    }, [isChatReady]);

    return {
        hasUnreadMessages,
        setHasUnreadMessages,
    };
};
