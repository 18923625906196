import { MessageToast } from 'kc-app/message-toast/message-toast';
import { KcProps } from 'keycloakify';
import { memo, useState } from 'react';
import { Input } from 'ui-components/input/input';
import { getMsg } from 'utils/kc-messages';
import { useFieldMessage } from 'utils/use-field-message';
import { useFormValidator } from 'utils/use-form-validator';
import { useReddirectToSignUp } from 'utils/use-reddirect-to-sign-up-on-email-error';
import { useSavedFieldLs } from 'utils/use-saved-field-ls';

import { Form } from '../../form/form';
import { KcContext_LoginUsername } from '../../kc-context';

export const LoginUsername = memo(({ kcContext }: { kcContext: KcContext_LoginUsername } & KcProps) => {
    const { url, login , message} = kcContext;
    const { msgStr, advancedMsgStr } = getMsg(kcContext);
    const [onChangeSavedField, getSavedField] = useSavedFieldLs();

    const [showSuccessMessage, setShowSuccessMessage] = useState(() => Boolean(message?.type === "success" && message?.summary));
    
    const emailError = useFieldMessage(['email', 'username'], kcContext);

    const [formErrors, resetFieldError, onSubmitValidated, submitDisabled] = useFormValidator(['username'], {
        username: emailError,
    });

    useReddirectToSignUp(kcContext);
    if(emailError) return null;



    return (
        <Form action={url.loginAction} kcContext={kcContext} onSubmit={onSubmitValidated}>
            {showSuccessMessage && <MessageToast message={message?.summary || ''} onClose={() => setShowSuccessMessage(false)} />}
            <Input
                type="text"
                id="username"
                name="username"
                defaultValue={login.username ?? getSavedField('email')}
                label={msgStr('email')}
                autoComplete="email"
                autoFocus={!emailError}
                errorMessage={advancedMsgStr(formErrors['email'])}
                onFocus={resetFieldError}
                onChange={onChangeSavedField}
            />

            <Input hidden type="checkbox" id="rememberMe" name="rememberMe" checked />

            <Input type="submit" value={msgStr('doLogIn')} disabled={submitDisabled} />
        </Form>
    );
});
