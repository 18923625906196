import b from 'b_';
import { defaultKcProps, getCurrentKcLanguageTag } from 'keycloakify';
import { KcApp as KcAppBase } from 'keycloakify/lib/components/KcApp';
import { memo, useMemo, useState } from 'react';
import { useCssAndCx } from 'tss-react';
import { Hubspot } from 'ui-components/hubspot/hubspot';
import { GoogleTagManager } from 'utility-components/google-tag-manager/google-tag-manager';
import { useShouldShowSupportChat } from 'utils/use-should-show-support-chat';

import { Header } from './header/header';
import { KcContextWithCustomPages } from './kc-context';
import { MessageToast } from './message-toast/message-toast';
import { Error } from './pages/error/error';
import { LoginConfigTotp } from './pages/login-config-totp/login-config-totp';
import { LoginOtp } from './pages/login-otp/login-otp';
import { LoginResetPassword } from './pages/login-reset-password/login-reset-password';
import { LoginUpdatePassword } from './pages/login-update-password/login-update-password';
import { LoginUsername } from './pages/login-username/login-username';
import { LoginVerifyEmail } from './pages/login-verify-email/login-verify-email';
import { Login } from './pages/login/login';
import { MagicLinkConfirm } from './pages/magic-link-confirm/magic-link-confirm';
import { MagicLinkForm } from './pages/magic-link-form/magic-link-form';
import { Register } from './pages/register/register';

// import { Terms } from './terms'; // Uncoment the line if you need to show Terms of Service

import './kc-messages-extension';

import '../prisma/variables.css';
import './kc-app.css';

const PAGES_WITH_VISIBLE_MESSAGE = ['login.ftl', 'register.ftl', 'login-update-password.ftl'];

export const KcApp = memo(({ kcContext }: { kcContext: KcContextWithCustomPages }) => {
    const keycloakLocale = useMemo(() => getCurrentKcLanguageTag(kcContext), [kcContext]);

    const [isToastClosed, setToastClosed] = useState(false);

    const shouldShowSupportChat = useShouldShowSupportChat({
        locale: keycloakLocale,
        allowedLocales: ['en'],
        allowedCountryCodes: ['US'],
    });

    const KeycloakComponent = useMemo(() => {
        switch (kcContext.pageId) {
            case 'login.ftl':
                return <Login {...{ kcContext, ...defaultKcProps }} />;
            case 'info.ftl':
            case 'error.ftl':
            case 'login-page-expired.ftl':
                return <Error {...{ kcContext, ...defaultKcProps }} />;
            case 'register.ftl':
                return <Register {...{ kcContext, ...defaultKcProps }} />;
            case 'login-verify-email.ftl':
                return <LoginVerifyEmail {...{ kcContext, ...defaultKcProps }} />;
            case 'login-reset-password.ftl':
                return <LoginResetPassword {...{ kcContext, ...defaultKcProps }} />;
            case 'login-update-password.ftl':
                return <LoginUpdatePassword {...{ kcContext, ...defaultKcProps }} />;

            case 'login-username.ftl':
                return <LoginUsername {...{ kcContext, ...defaultKcProps }} />;
            case 'magic-link-form.ftl':
                return <MagicLinkForm {...{ kcContext, ...defaultKcProps }} />;
            case 'magic-link-confirm.ftl':
                return <MagicLinkConfirm {...{ kcContext, ...defaultKcProps }} />;

            case 'login-otp.ftl':
                return <LoginOtp {...{ kcContext, ...defaultKcProps }} />;
            case 'login-config-totp.ftl':
                return <LoginConfigTotp {...{ kcContext, ...defaultKcProps }} />;

            // case 'terms.ftl':
            //     return <Terms {...{ kcContext, ...defaultKcProps }} />;

            default:
                return <KcAppBase {...{ kcContext, ...defaultKcProps }} />;
        }
    }, [kcContext]);

    const { cx } = useCssAndCx();

    const { message } = kcContext;
    const isMessageVisible = PAGES_WITH_VISIBLE_MESSAGE.includes(kcContext.pageId);

    return (
        <div className={cx(b('kc-app', 'main'), b('prisma', { theme: 'light' }))}>
            <Header />
            <div className={cx(b('kc-app', 'page'))}>{KeycloakComponent}</div>
            {!isToastClosed && isMessageVisible && message && message.summary && (
                <MessageToast message={message.summary} onClose={() => setToastClosed(true)} />
            )}
            <GoogleTagManager id="GTM-WGWNPDB" />
            {shouldShowSupportChat && <Hubspot containerId="hubspot-mount" chatFlowId="25196166" />}
        </div>
    );
});
