import b from 'b_';
import type { KcContextBase, KcProps } from 'keycloakify';
import { ChangeEventHandler, memo, useCallback } from 'react';
import { Input } from 'ui-components/input/input';
import { getMsg } from 'utils/kc-messages';
import { reddirectToSameUrlWithoutTab } from 'utils/reddirect-to-same-url-without-tab';
import { useAnalytics } from 'utils/use-analytics';
import { useFieldMessage } from 'utils/use-field-message';
import { useFormValidator } from 'utils/use-form-validator';
import { useSavedFieldLs } from 'utils/use-saved-field-ls';

import { Form } from '../../form/form';

import './login.css';

const validationFields = ['username', 'password'];

export const Login = memo(({ kcContext }: { kcContext: KcContextBase.Login } & KcProps) => {
    const { realm, url, login } = kcContext;
    const { msgStr, advancedMsgStr } = getMsg(kcContext);

    const [onChangeSavedField, getSavedField] = useSavedFieldLs();

    const emailError = useFieldMessage(['email', 'username'], kcContext);
    const passwordError = useFieldMessage(['password'], kcContext);
    const [formErrors, resetFieldError, onSubmitValidated, submitDisabled] = useFormValidator(validationFields, {
        username: emailError,
        password: passwordError,
    });

    const { sendGtmEvent } = useAnalytics();

    const handleClickEmail = useCallback(() => {
        sendGtmEvent('email_login_button_click');
    }, [sendGtmEvent]);

    const reddirectToLogin = useCallback<ChangeEventHandler<HTMLInputElement>>(
        e => {
            onChangeSavedField(e);
            reddirectToSameUrlWithoutTab();
        },
        [onChangeSavedField]
    );

    return (
        <Form action={url.loginAction} kcContext={kcContext} onSubmit={onSubmitValidated}>
            <Input
                type="text"
                tabIndex={1}
                id="username"
                name="username" // Keycloak needs it!!!
                defaultValue={login.username ?? getSavedField('email')}
                label={msgStr('email')}
                autoComplete="email"
                autoFocus={!emailError}
                errorMessage={advancedMsgStr(formErrors['email'])}
                onFocus={resetFieldError}
                onChange={reddirectToLogin}
            />

            <Input
                tabIndex={2}
                type="password"
                id="password"
                name="password"
                label={msgStr('password')}
                autoComplete="off"
                autoFocus={true}
                errorMessage={advancedMsgStr(formErrors['password'])}
                onFocus={resetFieldError}
            />

            <Input hidden tabIndex={3} type="checkbox" id="rememberMe" name="rememberMe" checked />

            <Input
                tabIndex={4}
                type="submit"
                value={msgStr('toThePlatform')}
                onClick={handleClickEmail}
                disabled={submitDisabled}
            />

            {realm.resetPasswordAllowed && (
                <a className={b('login', 'reset-password-link')} tabIndex={5} href={url.loginResetCredentialsUrl}>
                    {msgStr('doForgotPassword')}
                </a>
            )}
        </Form>
    );
});
