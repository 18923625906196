import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { cnDrawer } from '../drawer.constants';
import { useDragDown } from '../drawer.hooks/use-drag-down';
import './_open/drawer__backdrop_open.css';
import './drawer__backdrop.css';

export type DrawerBackdropProps = {
    isOpen: boolean;
    transitionTiming: number;
    onClick: () => void;
};

export const DrawerBackdrop: FC<DrawerBackdropProps> = ({ isOpen, transitionTiming, onClick }) => {
    const { onTouchStart, onTouchMove, onTouchEnd } = useDragDown(50, onClick);

    return (
        <CSSTransition in={isOpen} timeout={transitionTiming} unmountOnExit>
            <div
                className={cnDrawer('backdrop', { open: isOpen })}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                onClick={onClick}
            ></div>
        </CSSTransition>
    );
};
