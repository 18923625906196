import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconCopy24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon width={24} height={24} type="objects-copy-24" {...props}>
        <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.49681 6.99345V4.99365C8.49681 3.88858 9.39265 2.99274 10.4977 2.99274H14.4995C14.5011 2.99274 17.38 2.9926 18.5014 2.99274C19.623 2.99288 20.5023 3.90843 20.5023 4.99365V14.9982C20.5023 16.1033 19.6064 16.9991 18.5014 16.9991H16.5016V18.9989C16.5016 20.104 15.6057 20.9998 14.5006 20.9998H6.497C5.39193 20.9998 4.49609 20.104 4.49609 18.9989V8.99436C4.49609 7.88928 5.39193 6.99345 6.497 6.99345H8.49681ZM18.5014 4.9936V14.9982H16.5016V8.99436C16.5016 8.94218 16.4995 8.8904 16.4955 8.83909C16.4289 7.79227 15.6931 7.07337 14.6719 7.00065C14.6155 6.99589 14.5584 6.99345 14.5006 6.99345C13.4013 6.99331 10.6127 6.99344 10.5022 6.99345H10.4977V4.99365L18.5014 4.9936ZM8.49681 8.99436H6.497L6.497 18.9989H14.5006V16.9991H14.4995V8.99547L8.49681 8.99547V8.99436Z"
            fill="currentColor"
        />
    </Icon>
);
