import { KcContextBase } from 'keycloakify';

export const useFieldMessage = (fields: string[], kcContext: KcContextBase.Common): string => {
    const messages = fields.map(f => {
        if (kcContext?.messagesPerField.exists(f)) {
            return kcContext.messagesPerField.get(f);
        }
        return '';
    });

    return messages
        .filter(v => v)
        .join(' ')
        .trim();
};
