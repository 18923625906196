import { BrowserTracing, init as initSentry } from '@sentry/react';

export function setupSentry(environment: string) {
    initSentry({
        dsn: "https://c52fd40f18c2476c96e74413c75480a2@sentry.internal.practicum.com/41",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: environment,
        beforeSend: (event) => {
            event.user = {
                userEmail: sessionStorage.getItem('userEmail') || undefined,
                email: sessionStorage.getItem('email') || undefined,
                username: sessionStorage.getItem('username') || undefined,
                triedToLoginWithNonExistendEmail: sessionStorage.getItem('triedToLoginWithNonExistendEmail') === "true"
            }

            return event;
        }
    });
}
