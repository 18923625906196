import b from 'b_';
import { IconObjectsEye24 } from 'icons/objects/eye/icon-objects-eye-24';
import { IconObjectsEyecrossed24 } from 'icons/objects/eyecrossed/icon-objects-eyecrossed-24';
import { PrismIconTypeSymbolsExclamationcirclefilled24 } from 'icons/symbols/exclamationcircle/icon-symbols-exclamationcirclefilled-24';
import { ChangeEventHandler, forwardRef, HTMLProps, useCallback, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import { useCssAndCx } from 'tss-react';
import { InputType } from 'utils/types';

import './input.css';

export interface InputProps {
    errorMessage?: string;
    label?: string;
    activeError?: boolean; // для того чтобы просто input перевести в состояние ошибки, без message
}

export const Input = forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement> & InputProps>((props, outerRef) => {
    const { cx } = useCssAndCx();
    const { className, errorMessage, label, onChange, type, activeError, hidden, name, defaultValue, ...restProps } =
        props;
    const [value, setValue] = useState<string | number | undefined | readonly string[]>(defaultValue);
    const validateType: InputType[] = ['email', 'password', 'text'];
    const [isShownPassword, setIsShownPassword] = useState<boolean>(false);

    const isPasswordType = type === 'password';

    const newType = isShownPassword && isPasswordType ? 'text' : type;

    const showPassword = useCallback(() => {
        setIsShownPassword(!isShownPassword);
    }, [isShownPassword]);

    const isError = (!!errorMessage || activeError) && validateType.includes(newType as InputType);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        e => {
            const input = e.target;

            setValue(input.value);

            if (onChange) {
                onChange(e);
            }
        },
        [onChange]
    );

    return (
        <div className={b('input-wrapper', { hidden, type: newType })}>
            <div className="input__control-wrapper">
                <input
                    type={newType}
                    className={cx(b('input', { type: newType, empty: !value, error: isError }), className)}
                    ref={outerRef}
                    onChange={handleChange}
                    hidden={hidden}
                    name={name}
                    value={value}
                    {...restProps}
                />
                {label && <div className="input__label">{label}</div>}
                {isError && <PrismIconTypeSymbolsExclamationcirclefilled24 className="input__error-icon" />}
                {isPasswordType &&
                    !isError &&
                    (isShownPassword ? (
                        <IconObjectsEye24 onClick={showPassword} className="input__icon-eye" />
                    ) : (
                        <IconObjectsEyecrossed24 className="input__icon-eye" onClick={showPassword} />
                    ))}
            </div>
            <SlideDown className={cx('input__message-slidedown')} transitionOnAppear={false} closed={!isError}>
                {isError && <div className={b('input', 'message', { error: isError })}>{errorMessage}</div>}
            </SlideDown>
        </div>
    );
});
