import { SVGAttributes } from 'react';

import { Icon } from '../../icon';

export const IconObjectsEyecrossed24 = (props: SVGAttributes<SVGAElement>) => (
    <Icon width={24} height={24} type="objects-eye-24crossed" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L5.20502 6.61924C2.78516 8.3375 1.5 10.6828 1.5 12C1.5 14.25 5.25 19.5 12 19.5C13.9159 19.5 15.5902 19.077 17.0055 18.4198L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071ZM15.4791 16.8933L13.7052 15.1194C13.1879 15.3635 12.6099 15.5 12 15.5C9.79086 15.5 8 13.7091 8 11.5C8 10.8901 8.1365 10.3121 8.38062 9.79483L6.64263 8.05685C6.25822 8.31 5.89429 8.59077 5.55234 8.89673C4.28143 10.0339 3.5 11.4164 3.5 12C3.5 12.5836 4.28143 13.9661 5.55234 15.1033C7.23072 16.605 9.43844 17.5 12 17.5C13.2509 17.5 14.4174 17.2866 15.4791 16.8933ZM20.3202 16.0776C21.7511 14.6101 22.5 13.0055 22.5 12C22.5 9.75 18.75 4.5 12 4.5C10.9569 4.5 9.9854 4.62538 9.08833 4.84569L10.809 6.56635C11.1966 6.5225 11.5939 6.5 12 6.5C14.5616 6.5 16.7693 7.39502 18.4477 8.89673C19.7186 10.0339 20.5 11.4164 20.5 12C20.5 12.5099 19.9035 13.6296 18.9053 14.6627L20.3202 16.0776Z"
            fill="currentColor"
        />
    </Icon>
);
