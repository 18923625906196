import { useEffect, useState } from 'react';

export const useWidgetLoaded = (isChatReady: boolean) => {
    const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

    useEffect(() => {
        if (!isChatReady) {
            return;
        }

        const listener = () => {
            setIsWidgetLoaded(true);
        };

        window.HubSpotConversations?.on('widgetLoaded', listener);

        return () => {
            window.HubSpotConversations?.off('widgetLoaded', listener);
        };
    }, [isChatReady]);

    return isWidgetLoaded;
};
